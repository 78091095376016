import React, { Component } from 'react';
import { bool, func, object, node, number, shape, string, arrayOf } from 'prop-types';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';

import { useRouteConfiguration } from '../../../context/routeConfigurationContext';
import { FormattedMessage, useIntl, intlShape } from '../../../util/reactIntl';
import { propTypes } from '../../../util/types';
import { createResourceLocatorString } from '../../../util/routes';

import { ModalInMobile, Button, OutsideClickHandler, IconCard } from '../../../components';

import PopupOpenerButton from '../PopupOpenerButton/PopupOpenerButton';
import css from './SearchFiltersMobile.module.css';

class SearchFiltersMobileComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { isFiltersOpenOnMobile: false, initialQueryParams: null, isOpen: false };
    this.modalRef = React.createRef();

    this.openFilters = this.openFilters.bind(this);
    this.cancelFilters = this.cancelFilters.bind(this);
    this.closeFilters = this.closeFilters.bind(this);
    this.resetAll = this.resetAll.bind(this);
  }

  // Open filters modal, set the initial parameters to current ones
  openFilters() {
    const { onOpenModal, urlQueryParams } = this.props;
    onOpenModal();
    this.setState({ isFiltersOpenOnMobile: true, initialQueryParams: urlQueryParams });
  }

  // Close the filters by clicking cancel, revert to the initial params
  cancelFilters() {
    const { history, onCloseModal, routeConfiguration } = this.props;

    history.push(
      createResourceLocatorString(
        'SearchPage',
        routeConfiguration,
        {},
        this.state.initialQueryParams
      )
    );
    onCloseModal();
    this.setState({ isFiltersOpenOnMobile: false, initialQueryParams: null });
  }

  // Close the filter modal
  closeFilters() {
    this.props.onCloseModal();
    this.setState({ isFiltersOpenOnMobile: false });
  }

  // Reset all filter query parameters
  resetAll(e) {
    this.props.resetAll(e);

    // blur event target if event is passed
    if (e && e.currentTarget) {
      e.currentTarget.blur();
    }
  }
  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    if (this.modalRef && !this.modalRef.current.contains(event.target)) {
      // this.toggleModal();
    }
  };

  toggleModal = (val) => {
    this.setState(prevState => ({
      isOpen: val
    }))
  };

  render() {
    const { isOpen } = this.state;
    const {
      rootClassName,
      className,
      children,
      sortByComponent,
      listingsAreLoaded,
      resultsCount,
      searchInProgress,
      showAsModalMaxWidth,
      onMapIconClick,
      onManageDisableScrolling,
      selectedFiltersCount,
      noResultsInfo,
      intl,
      isMapVariant,
    } = this.props;

    const classes = classNames(rootClassName || css.root, className);

    const resultsFound = (
      <FormattedMessage id="SearchFiltersMobile.foundResults" values={{ count: resultsCount }} />
    );
    const noResults = <FormattedMessage id="SearchFiltersMobile.noResults" />;
    const loadingResults = <FormattedMessage id="SearchFiltersMobile.loadingResults" />;
    const filtersHeading = intl.formatMessage({ id: 'SearchFiltersMobile.heading' });
    const modalCloseButtonMessage = intl.formatMessage({ id: 'SearchFiltersMobile.cancel' });

    const showListingsLabel = intl.formatMessage(
      { id: 'SearchFiltersMobile.showListings' },
      // { count: resultsCount }
    );


    return (

      <div className={classes}>
        <div className={css.searchResultSummary}>
          {listingsAreLoaded && resultsCount > 0 ? resultsFound : null}
          {listingsAreLoaded && resultsCount === 0 ? noResults : null}
          {searchInProgress ? loadingResults : null}
        </div>
        <OutsideClickHandler onOutsideClick={() => this.toggleModal(false)} className={css.dateDropdown}>
          <div className={css.buttons}>
            {/* <PopupOpenerButton
            className={css.filterButton}
            isSelected={selectedFiltersCount > 0}
            toggleOpen={this.openFilters}
            onClick={this.toggleModal}
          >
            <FormattedMessage
              id="SearchFiltersMobile.filtersButtonLabel"
              className={css.mapIconText}
            />
          </PopupOpenerButton> */}

            <button
              onClick={(e) => {
                e.preventDefault();
                this.toggleModal(true)
              }}>
              <IconCard brand="filtermobile" />
              <FormattedMessage
                id="SearchFiltersMobile.filtersButtonLabel"
                className={css.mapIconText}
              />
            </button>
            <div className={css.sortByFilter}>
              {sortByComponent}
            </div>

            {isMapVariant ? (
              <div className={css.mapIcon} onClick={onMapIconClick}>
                <FormattedMessage id="SearchFiltersMobile.openMapView" className={css.mapIconText} />
              </div>
            ) : null}
          </div>

          {noResultsInfo ? noResultsInfo : null}

          <ModalInMobile
            className={css.filterMobileBox}
            id="SearchFiltersMobile.filters"
            isModalOpenOnMobile={this.state.isFiltersOpenOnMobile}
            onClose={this.cancelFilters}
            showAsModalMaxWidth={showAsModalMaxWidth}
            onManageDisableScrolling={onManageDisableScrolling}
            containerClassName={css.modalContainer}
            closeButtonMessage={modalCloseButtonMessage}
          >
            <div className={css.modalHeadingWrapper}>
              <span className={css.modalHeading}>{filtersHeading}</span>
              <button className={css.resetAllButton} onClick={e => this.resetAll(e)}>
                <FormattedMessage id={'SearchFiltersMobile.resetAll'} />
              </button>
            </div>
            {/* {this.state.isFiltersOpenOnMobile ? (
            <div className={css.filtersWrapper}>{children}</div>
          ) : null} */}


          </ModalInMobile>
          <div
            ref={this.modalRef}
            className={`${css.modalContainerss} ${isOpen ? css.open : ''}`}
          >
            <div className={css.modalContentaaa}>
              <div className={css.centertopLine}><span /></div>
              <div className={css.filtersWrapper}>{children}</div>
              <div className={css.showListingsContainer}>
                <Button className={css.showListingsButton} onClick={() => this.setState({ isOpen: false })}>
                  <FormattedMessage id="SearchFiltersMobile.applyFilters" />
                </Button>
              </div>
            </div>
          </div>
        </OutsideClickHandler>
      </div>

    );
  }
}

SearchFiltersMobileComponent.defaultProps = {
  rootClassName: null,
  className: null,
  sortByComponent: null,
  resultsCount: null,
  searchInProgress: false,
  selectedFiltersCount: 0,
  isMapVariant: true,
  onMapIconClick: () => { },
};

SearchFiltersMobileComponent.propTypes = {
  rootClassName: string,
  className: string,
  urlQueryParams: object.isRequired,
  sortByComponent: node,
  listingsAreLoaded: bool.isRequired,
  resultsCount: number,
  searchInProgress: bool,
  showAsModalMaxWidth: number.isRequired,
  onMapIconClick: func,
  onManageDisableScrolling: func.isRequired,
  onOpenModal: func.isRequired,
  onCloseModal: func.isRequired,
  resetAll: func.isRequired,
  selectedFiltersCount: number,
  isMapVariant: bool,

  // from useIntl
  intl: intlShape.isRequired,

  // from useRouteConfiguration
  routeConfiguration: arrayOf(propTypes.route).isRequired,

  // from useHistory
  history: shape({
    push: func.isRequired,
  }).isRequired,
};

const SearchFiltersMobile = props => {
  const routeConfiguration = useRouteConfiguration();
  const intl = useIntl();
  const history = useHistory();

  return (
    <SearchFiltersMobileComponent
      routeConfiguration={routeConfiguration}
      intl={intl}
      history={history}
      {...props}
    />
  );
};

export default SearchFiltersMobile;
