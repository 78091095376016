import React, { useEffect, useRef, useState } from 'react';
import { arrayOf, func, number, shape, string } from 'prop-types';
import classNames from 'classnames';

import { IconCard, Menu, MenuContent, MenuItem, MenuLabel } from '../../../components';
import css from './SortByPopup.module.css';
import { FormattedMessage } from 'react-intl';

const optionLabel = (options, key) => {
  const option = options.find(o => o.key === key);
  return option ? option.label : key;
};

const SortByIcon = props => {
  const classes = classNames(css.icon, props.className);
  // extra small arrow head (down)
  return (
    <svg className={classes} width="8" height="5" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.764 4.236c.131.13.341.13.472 0l2.666-2.667a.333.333 0 10-.471-.471L4 3.528l-2.43-2.43a.333.333 0 10-.471.471l2.665 2.667z"
        fill="#4A4A4A"
        stroke="#4A4A4A"
        fillRule="evenodd"
      />
    </svg>
  );
};

const SortByPopup = props => {
  const [isOpen, setIsOpen] = useState(false);
  const {
    rootClassName,
    className,
    menuLabelRootClassName,
    urlParam,
    label,
    options,
    initialValue,
    contentPlacementOffset,
    onSelect,
    mode
  } = props;

  const onToggleActive = isOpenParam => {
    setIsOpen(isOpenParam);
  };

  const selectOption = (urlParameter, option) => {
    setIsOpen(false);
    onSelect(urlParameter, option);
  };

  // resolve menu label text and class
  const menuLabel = initialValue ? optionLabel(options, initialValue) : label;

  const classes = classNames(rootClassName || css.root, className);
  const menuLabelClasses = classNames(menuLabelRootClassName);
  const iconArrowClassName = isOpen ? css.iconArrowAnimation : null;
  const modalRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = event => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    // Add event listener
    document.addEventListener('mousedown', handleClickOutside);

    // Cleanup event listener on component unmount
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  const toggleModal = (val) => {
    setIsOpen(val);
  };


  return (
    <>
      {mode == "mobile" ?
        <div className={css.sortByMobile}>
          <div className={css.sortByHeading} onClick={toggleModal}>
            <IconCard brand="sortbyfilter" />
            <FormattedMessage id="MainPanelHeader.sortBy" />
          </div>
          <div
            ref={modalRef}
            className={`${css.modalContainerss} ${isOpen ? css.open : ''}`}
          >
            <div className={css.modalsortFilter}>
              <div className={css.centertopLine}><span /></div>
              <div className={css.filtersWrapper}>
                <div className={css.sortByTitle}>
                  <FormattedMessage id="MainPanelHeader.sortByTitle" />
                </div>
                {options.map(option => {
                  const selected = initialValue === option.key;
                  const buttonClass = classNames(css.sortItem, { [css.active]: selected });

                  return (
                    <div key={option.key}>
                      <button
                        className={buttonClass}
                        disabled={option.disabled}
                        onClick={() => (selected ? null : selectOption(urlParam, option.key))}
                      >
                        {option.longLabel || option.label}
                      </button>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        :
        <Menu
          className={classes}
          useArrow={false}
          contentPlacementOffset={contentPlacementOffset}
          contentPosition="left"
          onToggleActive={onToggleActive}
          isOpen={isOpen}
        >
          <MenuLabel rootClassName={menuLabelClasses}>
            <span>
              <span className={css.sortyBy}>
                <FormattedMessage id="MainPanelHeader.sortBy" />
              </span>
              {menuLabel}
            </span>
            <IconCard brand="downarrow" />
          </MenuLabel>
          <MenuContent className={css.menuContent}>
            {options.map(option => {
              // check if this option is selected
              const selected = initialValue === option.key;
              // menu item border class
              const menuItemBorderClass = selected ? css.menuItemBorderSelected : css.menuItemBorder;

              return (
                <MenuItem key={option.key}>
                  <button
                    className={css.menuItem}
                    disabled={option.disabled}
                    onClick={() => (selected ? null : selectOption(urlParam, option.key))}
                  >
                    <span className={menuItemBorderClass} />
                    {option.longLabel || option.label}
                  </button>
                </MenuItem>
              );
            })}
          </MenuContent>
        </Menu>}
    </>
  );
};

SortByPopup.defaultProps = {
  rootClassName: null,
  className: null,
  menuLabelRootClassName: null,
  initialValue: null,
  contentPlacementOffset: 0,
};

SortByPopup.propTypes = {
  rootClassName: string,
  className: string,
  menuLabelRootClassName: string,
  urlParam: string.isRequired,
  label: string.isRequired,
  onSelect: func.isRequired,
  options: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ).isRequired,
  initialValue: string,
  contentPlacementOffset: number,
};

export default SortByPopup;
